/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    code: "code",
    ul: "ul",
    li: "li",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "custom-templates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#custom-templates",
    "aria-label": "custom templates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Custom Templates"), "\n", React.createElement(_components.p, null, "Customize template to personalize the result React component."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "custom-component-template",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#custom-component-template",
    "aria-label": "custom component template permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Custom Component template"), "\n", React.createElement(_components.p, null, "A custom template takes place in a file that exports a \"template function\"."), "\n", React.createElement(_components.p, null, "This function is called in a babel plugin: ", React.createElement(_components.code, null, "babel-plugin-transform-svg-component"), " and must returns a Babel AST. If you are not familiar with all Babel stuff, you should read ", React.createElement(_components.a, {
    href: "https://github.com/jamiebuilds/babel-handbook"
  }, "this guide"), "."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "variables"), ": All pre-compiled variables by SVGR", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "componentName"), ": The component name"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "props"), ": The properties"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "interfaces"), ": All necessary interfaces (typescript)"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "imports"), ": All necessary imports"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "exports"), ": The export of the component"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "jsx"), ": The JSX part of the component"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "context"), ":", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "tpl"), ": A built-in template-tag function to create template"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "options"), ": Options passed to ", React.createElement(_components.code, null, "babel-plugin-transform-svg-component")), "\n"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "The following template is the default template used by SVGR. It is a good idea to start with it:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const template = (variables, { tpl }) => {\n  return tpl`\n${variables.imports};\n\n${variables.interfaces};\n\nconst ${variables.componentName} = (${variables.props}) => (\n  ${variables.jsx}\n);\n\n${variables.exports};\n`\n}\n\nmodule.exports = template\n")), "\n", React.createElement(_components.p, null, "Let's try something very simple. You want to add some PropTypes to your component:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const propTypesTemplate = (\n  { imports, interfaces, componentName, props, jsx, exports },\n  { tpl },\n) => {\n  return tpl`${imports}\nimport PropTypes from 'prop-types';\n${interfaces}\n\nfunction ${componentName}(${props}) {\n  return ${jsx};\n}\n\n${componentName}.propTypes = {\n  title: PropTypes.string,\n};\n\n${exports}\n  `\n}\n\nmodule.exports = propTypesTemplate\n")), "\n", React.createElement(_components.p, null, "As you can see it is very natural, we just add code and use AST parts in the template."), "\n", React.createElement(_components.h3, {
    id: "use-with-cli",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-with-cli",
    "aria-label": "use with cli permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use with CLI"), "\n", React.createElement(_components.p, null, "You can use component template in the CLI:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npx @svgr/cli --template template.js -- my-icon.svg\n")), "\n", React.createElement(_components.h3, {
    id: "use-with-config",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-with-config",
    "aria-label": "use with config permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use with config"), "\n", React.createElement(_components.p, null, "Specify the template in ", React.createElement(_components.code, null, ".svgrrc.js"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// .svgrrc.js\nmodule.exports = {\n  template: require('./my-template'),\n}\n")), "\n", React.createElement(_components.h2, {
    id: "custom-index-template",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#custom-index-template",
    "aria-label": "custom index template permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Custom index template"), "\n", React.createElement(_components.p, null, "When you use the CLI with ", React.createElement(_components.code, null, "--out-dir"), " option, an index file is automatically generated."), "\n", React.createElement(_components.p, null, "The customization is the same, a file that exports a function. The function receives an argument that is an array containing objects made of the original file path and the path of the file containing the generated React component:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "originalPath"), ": the original file's path"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "path"), ": the React component's file's path"), "\n"), "\n", React.createElement(_components.p, null, "Default template only uses ", React.createElement(_components.code, null, "path"), " and it is similar to this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const path = require('path')\n\nfunction defaultIndexTemplate(filePaths) {\n  const exportEntries = filePaths.map(({ path: filePath }) => {\n    const basename = path.basename(filePath, path.extname(filePath))\n    const exportName = /^\\d/.test(basename) ? `Svg${basename}` : basename\n    return `export { default as ${exportName} } from './${basename}'`\n  })\n  return exportEntries.join('\\n')\n}\n\nmodule.exports = defaultIndexTemplate\n")), "\n", React.createElement(_components.p, null, "but you could implement a more advanced template exploiting the original file name too:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const path = require('path')\n\nfunction defaultIndexTemplate(filePaths) {\n  const entries = filePaths.map(({ path: filePath, originalPath }) => {\n    const originalFileName = path.basename(\n      originalPath,\n      path.extname(originalPath),\n    )\n    const basename = path.basename(filePath, path.extname(filePath))\n    const exportName = /^\\d/.test(basename) ? `Svg${basename}` : basename\n    const importLine = `import ${exportName} from './${basename}';`\n    const mapLine = `${\n      /.*[.-].*/.test(originalFileName)\n        ? `'${originalFileName}'`\n        : originalFileName\n    }: ${exportName}`\n    return { importLine, mapLine }\n  })\n  return `${entries.map(({ importLine }) => importLine).join('\\n')}\nexport const map = {\n${entries.map(({ mapLine }) => mapLine).join(',\\n')}\n}\n`\n}\n\nmodule.exports = defaultIndexTemplate\n")), "\n", React.createElement(_components.h3, {
    id: "use-with-cli-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-with-cli-1",
    "aria-label": "use with cli 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use with CLI"), "\n", React.createElement(_components.p, null, "You can use component template in the CLI:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npx @svgr/cli --index-template index-template.js -- my-icon.svg\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
